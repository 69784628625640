<template>
  <v-list-item class="mb-0 justify-space-between pl-3">
    <a href="https://www.softclient.com.au/">
      <v-list-item-avatar>
        <v-img :src="require('@/assets/sclogo.png')" />
      </v-list-item-avatar>
    </a>
    <v-list-item-content class="pl-2">
      <v-list-item-title class="text-h3">
        <strong class="mr-1 font-weight-black">my</strong>

        <span class="secondary--text">Taxis</span>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
// Utilities
import { get } from "vuex-pathify";

export default {
  name: "DefaultDrawerHeader",

  computed: { version: get("app/version") },
};
</script>
